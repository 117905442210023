import { Reducer } from "react";
import { Actions } from "../actions/common";
import { listsReducer } from "./listsReducer";
import { selectedItemReducer } from "./selectedItemReducer";
import { selectedListReducer } from "./selectedListReducer";
import { selectedEventReducer } from "./selectedEventReducer";
import { invokerReducer } from "./invokerUserReducer";
import { selectedProjectFiltersReducer } from "./selectedProjectFiltersReducer";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const combineReducers = (slices: { [key: string]: Reducer<any, Actions> }) => (prevState: any, action: Actions) =>
    Object.keys(slices).reduce(
        (nextState, nextProp) => ({
            ...nextState,
            [nextProp]: slices[nextProp](prevState[nextProp], action)
        }),
        prevState
    );

export default combineReducers({
    lists: listsReducer,
    selectedList: selectedListReducer,
    selectedItem: selectedItemReducer,
    selectedEvent: selectedEventReducer,
    invoker: invokerReducer,
    selectedProjectFilters: selectedProjectFiltersReducer
});
