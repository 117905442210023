import Box from '@mui/material/Box';
import { MeetingItemStatus } from '../models';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import {darken, lighten } from '@mui/material/styles';

const ItemStatus = ({ status, lastModifiedBy }) => {
    const { t } = useTranslation();
    const getStatusColor = () => {
        let color = "none";
        if (status === MeetingItemStatus.NotStarted) {
            color = "#00508B";
        }
        if (status === MeetingItemStatus.InProgress) {
            color = "#F9F871"
        }
        if (status === MeetingItemStatus.Completed) {
            color = "#4E7D46";
        }
        if (status === MeetingItemStatus.Cancelled) {
            color = "#de775d";
        }
        if (status === MeetingItemStatus.OnHold) {
            color = "#777586";
        }
        return color;
    }

    // const getBorder = () => {
    //     return statusKeys.find(entry => entry === status) ? '1px solid lightgray' : 'none';
    // }

    function getContrast() {
        const hexcolor = getStatusColor();
        const r = parseInt(hexcolor.substring(1, 3), 16);
        const g = parseInt(hexcolor.substring(3, 5), 16);
        const b = parseInt(hexcolor.substring(5, 7), 16);
        const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return (yiq >= 128) ? 'black' : 'white';
    }

    return (
        <Tooltip title={lastModifiedBy ? t('item_status.last_updated_by', { updater: lastModifiedBy }) : ''}>
            <Box
                sx={{
                    "& .MuiPaper-root": {
                    border: '1px solid grey[300]',
                    borderRadius: '5% 5% 5% 5%',
                    backgroundColor: lighten(getStatusColor(), 0.5),
                    color: getContrast(),
                    width: 'auto',
                    height: '32px',
                    lineHeight: '32px',
                    margin: '10px 0',
                    textAlign: 'center',
                    overflow: 'hidden',
                        // clipPath: "polygon(0 50%,10% 0,100% 0,100% 80%,90% 100%,0% 100%)"
                    },
                }}>
                <Paper elevation={4}> 
                    {t(`item_status.${status}`)}
                </Paper>
            </Box>
        </Tooltip>
    );
};

export default ItemStatus;