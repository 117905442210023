import { Project } from "./project"

export enum UserRole {
    Admin = "admin",
    Creator = "creator",
    Actor = "actor"
}

export interface ApiUser {
    id?: string,
    email: string,
    role: UserRole,
    projects?: Project[],
    invitedUserEmails?: string[],
    termsAcceptanceDate?: Date,
    firstName?: string,
    lastName?: string,
    organization?: string,
    createdBy?: string,
    comments?: string,
    createdDate?: Date,
    updatedDate?: Date,
}