
import axios, { AxiosInstance } from 'axios';
import { BrowserAuthError, InteractionRequiredAuthError, SilentRequest } from '@azure/msal-browser';
import { jwtDecode } from 'jwt-decode';
import { loginRequest } from '../authConfig';
import { getMsalInstance } from './authUtility';
import { parseSelectedProjectIds, parseSelectedTagIds } from '../util/filterHandler';

export const LAST_LOGIN_REDIRECT = 'lastLoginRedirect';

async function getSilentToken(): Promise<{ accessCode: string, idToken: string } | undefined> {
    try {
        const instance = getMsalInstance();
        const currentAccount = instance.getActiveAccount();
        const accessTokenRequest: SilentRequest = {
            scopes: loginRequest.scopes, // Scopes for which you want to acquire the token
            account: currentAccount ? currentAccount : undefined,
        };
        try {
            const result = await instance.acquireTokenSilent(accessTokenRequest);
            const idToken = jwtDecode(result.idToken);
            if (idToken.exp > (new Date().getTime() / 1000 + 2)) {
                return { accessCode: result.accessToken, idToken: result.idToken };
            }
            const accessToken = jwtDecode(result.idToken);
            console.info(`Id token expires on ${idToken.exp}; access token expires on ${accessToken.exp}; acquiring token again with refresh`);
            const refreshed = await instance.acquireTokenSilent({ ...accessTokenRequest, forceRefresh: true });
            return { accessCode: refreshed.accessToken, idToken: refreshed.idToken };

        } catch (error) {
            if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
                console.info('Silent token acquisition failed. Acquiring token using redirect');
                localStorage.setItem(LAST_LOGIN_REDIRECT, new Date().getTime().toString());
                await instance.acquireTokenRedirect(accessTokenRequest);
                // console.info(`Token acquired using redirect: ${result.accessToken} ${result.idToken}`);
            } else {
                throw error;
            }
        }
    } catch (error) {
        console.error('Error while retrieving token:', error);
    }
}

function getAxiosFactory(baseUrl: string, baseRoute: string): () => Promise<AxiosInstance> {
    return async () => {
        const token = await getSilentToken();
        const axiosInstance = axios.create({
            baseURL: `${baseUrl}${baseRoute}`,
            headers: {
                "Authorization": `Bearer ${token.accessCode}`,
                "Identity": `${token.idToken}`,
                "projectids": parseSelectedProjectIds().join(','),
                "tagids": parseSelectedTagIds().join(','),
                "Content-Type": "application/json"
            }
        });
        axiosInstance.interceptors.response.use((response) => response, (error) => {
            const lastRedirectTime = localStorage.getItem(LAST_LOGIN_REDIRECT);
            const isLastRedirectOldEnough = new Date(+lastRedirectTime).getTime() < new Date().getTime() - 1000 * 60;
            console.error(`Error on Axios response; isLastRedirectOldEnough: ${isLastRedirectOldEnough}`, error);
            if (error.response?.status === 401 && isLastRedirectOldEnough) {
                localStorage.setItem(LAST_LOGIN_REDIRECT, new Date().getTime().toString());
                const instance = getMsalInstance();
                const currentAccount = instance.getActiveAccount();
                instance.acquireTokenRedirect({
                    scopes: loginRequest.scopes,
                    account: currentAccount ? currentAccount : undefined,
                });
            }
        });
        return axiosInstance;
    }
}

export default getAxiosFactory;