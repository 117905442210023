import { QueryOptions } from "@testing-library/react";
import { Dispatch } from "react";
import { Meeting } from "../models";
import { MeetingService } from "../services/listService";
import { ActionTypes } from "./common";
import config from "../config"
import { trackEvent } from "../services/telemetryService";
import { ActionMethod, createPayloadAction, PayloadAction } from "./actionCreators";
import getAxiosFactory from "../services/customAxios";

const listService = new MeetingService(config.api.baseUrl, '/meetings');

export interface ListActions {
    list(options?: QueryOptions): Promise<Meeting[]>
    load(id: string): Promise<Meeting>
    select(list: Meeting): Promise<Meeting>
    save(list: Meeting): Promise<Meeting>
    remove(id: string): Promise<void>
}

export const list = (options?: QueryOptions): ActionMethod<Meeting[]> => async (dispatch: Dispatch<ListListsAction>) => {
    const lists = await listService.getList(options);
    dispatch(listListsAction(lists));

    return lists;
}

export const select = (list: Meeting): ActionMethod<Meeting> => (dispatch: Dispatch<SelectListAction>) => {
    dispatch(selectListAction(list));

    return Promise.resolve(list);
}

export const load = (id: string): ActionMethod<Meeting> => async (dispatch: Dispatch<LoadListAction>) => {
    const list = await listService.get(id);

    dispatch(loadListAction(list));

    return list;
}

export const save = (list: Meeting): ActionMethod<Meeting> => async (dispatch: Dispatch<SaveListAction>) => {
    const newList = await listService.save(list);

    dispatch(saveListAction(newList));

    trackEvent(ActionTypes.SAVE_LIST.toString());

    return newList;
}

export const remove = (id: string): ActionMethod<void> => async (dispatch: Dispatch<DeleteListAction>) => {
    await listService.delete(id);

    dispatch(deleteListAction(id));
}

export interface ListListsAction extends PayloadAction<string, Meeting[]> {
    type: ActionTypes.LOAD_LISTS
}

export interface SelectListAction extends PayloadAction<string, Meeting | undefined> {
    type: ActionTypes.SELECT_LIST
}

export interface LoadListAction extends PayloadAction<string, Meeting> {
    type: ActionTypes.LOAD_LIST
}

export interface SaveListAction extends PayloadAction<string, Meeting> {
    type: ActionTypes.SAVE_LIST
}

export interface DeleteListAction extends PayloadAction<string, string> {
    type: ActionTypes.DELETE_LIST
}

const listListsAction = createPayloadAction<ListListsAction>(ActionTypes.LOAD_LISTS);
const selectListAction = createPayloadAction<SelectListAction>(ActionTypes.SELECT_LIST);
const loadListAction = createPayloadAction<LoadListAction>(ActionTypes.LOAD_LIST);
const saveListAction = createPayloadAction<SaveListAction>(ActionTypes.SAVE_LIST);
const deleteListAction = createPayloadAction<DeleteListAction>(ActionTypes.DELETE_LIST);

export const getArchived = async (): Promise<Meeting[]> => {
    const axiosFactory = getAxiosFactory(config.api.baseUrl, '/meetings');
    const realClient = await axiosFactory();
    // rewrite the above to allow an archived=true query parameter
    const response = await realClient.request<Meeting[]>({
        method: 'GET',
        params: {
            archived: true
        }
    });
    return response?.data || [];
}
