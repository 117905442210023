import * as React from 'react';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface TooltipIconProps {
    titleKey: string;
    icon: JSX.Element;
}


export const TooltipIcon: React.FC<TooltipIconProps> = (props: TooltipIconProps): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <Tooltip title={t(props.titleKey)}>
            {props.icon}
        </Tooltip>
    );
}