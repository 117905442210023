import { QueryOptions } from "@testing-library/react";
import { Dispatch } from "react";
import { MeetingItem } from "../models";
import { ItemService } from "../services/itemService";
import { ActionTypes } from "./common";
import config from "../config"
import { ActionMethod, createPayloadAction, PayloadAction } from "./actionCreators";

export interface ItemActions {
    list(listId: string, options?: QueryOptions): Promise<MeetingItem[]>
    select(item?: MeetingItem): Promise<MeetingItem>
    load(listId: string, id: string): Promise<MeetingItem>
    save(listId: string, Item: MeetingItem): Promise<MeetingItem>
    remove(listId: string, Item: MeetingItem): Promise<void>
}

export const list = (listId: string, options?: QueryOptions): ActionMethod<MeetingItem[]> => async (dispatch: Dispatch<ListItemsAction>) => {
    const itemService = new ItemService(config.api.baseUrl, `/meetings/${listId}/items`);
    const items = await itemService.getList(options);

    dispatch(listItemsAction(items));

    return items;
}

export const select = (item?: MeetingItem): ActionMethod<MeetingItem | undefined> => async (dispatch: Dispatch<SelectItemAction>) => {
    dispatch(selectItemAction(item));

    return Promise.resolve(item);
}

export const load = (listId: string, id: string): ActionMethod<MeetingItem> => async (dispatch: Dispatch<LoadItemAction>) => {
    const itemService = new ItemService(config.api.baseUrl, `/meetings/${listId}/items`);
    const item = await itemService.get(id);

    dispatch(loadItemAction(item));

    return item;
}

export const save = (listId: string, item: MeetingItem): ActionMethod<MeetingItem> => async (dispatch: Dispatch<SaveItemAction>) => {
    const itemService = new ItemService(config.api.baseUrl, `/meetings/${listId}/items`);
    const newItem = await itemService.save(item);

    dispatch(saveItemAction(newItem));

    return newItem;
}

export const remove = (listId: string, item: MeetingItem): ActionMethod<void> => async (dispatch: Dispatch<DeleteItemAction>) => {
    const itemService = new ItemService(config.api.baseUrl, `/meetings/${listId}/items`);
    if (item.id) {
        await itemService.delete(item.id);
        dispatch(deleteItemAction(item.id));
    }
}

export interface ListItemsAction extends PayloadAction<string, MeetingItem[]> {
    type: ActionTypes.LOAD_ITEMS
}

export interface SelectItemAction extends PayloadAction<string, MeetingItem | undefined> {
    type: ActionTypes.SELECT_ITEM
}

export interface LoadItemAction extends PayloadAction<string, MeetingItem> {
    type: ActionTypes.LOAD_ITEM
}

export interface SaveItemAction extends PayloadAction<string, MeetingItem> {
    type: ActionTypes.SAVE_ITEM
}

export interface DeleteItemAction extends PayloadAction<string, string> {
    type: ActionTypes.DELETE_ITEM
}

const listItemsAction = createPayloadAction<ListItemsAction>(ActionTypes.LOAD_ITEMS);
const selectItemAction = createPayloadAction<SelectItemAction>(ActionTypes.SELECT_ITEM);
const loadItemAction = createPayloadAction<LoadItemAction>(ActionTypes.LOAD_ITEM);
const saveItemAction = createPayloadAction<SaveItemAction>(ActionTypes.SAVE_ITEM);
const deleteItemAction = createPayloadAction<DeleteItemAction>(ActionTypes.DELETE_ITEM);
