import { Box, Typography, Button, TextField, Tooltip, FormControl, MenuItem, Select, Checkbox, Divider } from "@mui/material";
import { FC, ReactElement, useContext, useState } from "react";
import { MeetingItemRepresentation } from "../models/meetingItemRepresentation";
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import ItemStatus from "./itemStatus";
import dateFormatter, { dateTimeFormatter } from "../util/dateFormatter";
import InfoIcon from '@mui/icons-material/Info';
import TaskIcon from '@mui/icons-material/Task';
import SignpostIcon from '@mui/icons-material/Signpost';
import SendIcon from '@mui/icons-material/Send';
import { AppContext } from "../models/applicationState";
import { Context } from "./context";
import { useMsal } from "@azure/msal-react";
import { Meeting, MeetingItem, MeetingItemStatus, enumFromStringValue } from "../models";
import { FreeSingleSelect } from "./freeSingleSelect";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { TFunction } from "i18next";
import CheckIcon from '@mui/icons-material/Check';
import { TooltipIcon } from "./tooltipIcon";
import ClearIcon from '@mui/icons-material/Clear';
import { AttachmentUpload } from "./attachmentUpload";

interface ItemEditingPanelProps {
    meetingItem: MeetingItemRepresentation
    open: boolean
    meeting?: Meeting
    allowMetadataEditing?: boolean
    allowAttachmentsEditing?: boolean
    readOnly?: boolean
    onClose: () => Promise<void> | void
    onUpdate: (item: MeetingItemRepresentation) => Promise<void> | void
    onAttachmentsCountChanged?: (count: number, meetingId?: string, itemId?: string) => Promise<void> | void;
}

const COMMENT_LINE_SEPARATOR = '-----';

export const hasUserComments = (item: MeetingItemRepresentation) => {
    return item?.comments?.includes(COMMENT_LINE_SEPARATOR);
}
const addCommentToItem = (item: MeetingItemRepresentation, userFullName: string, comment: string, t: TFunction<"translation", undefined>) => {
    addCommentToItemInternal(item, userFullName, comment, COMMENT_LINE_SEPARATOR);
}

export const addStatusUpdateSystemCommentToItem = (item: MeetingItemRepresentation, userFullName: string, t: TFunction<"translation", undefined>) => {
    addCommentToItemInternal(item, userFullName, t('item.status_changed') + ': ' + t(`item_status.${item.status}`));
}

export const addPublishSystemCommentToItem = (item: MeetingItemRepresentation | MeetingItem, userFullName: string, t: TFunction<"translation", undefined>) => {
    addCommentToItemInternal(item, userFullName, t('meeting_status.published'));
}

const addCommentToItemInternal = (item: MeetingItemRepresentation | MeetingItem, userFullName: string, comment: string, separator?: string) => {
    if (!item.comments) {
        item.comments = '';
    }
    if (separator) {
        item.comments += separator + '\n';
    }
    item.comments += `[${userFullName} - ${dateTimeFormatter.format(new Date())}]: ${comment}`;
    if (separator) {
        item.comments += `\n` + separator;
    }
    item.comments += '\n';
}

export const ItemEditingPanel: FC<ItemEditingPanelProps> = (props: ItemEditingPanelProps): ReactElement => {
    const { t } = useTranslation();
    const [content, setContent] = useState(props.meetingItem.content);
    const [category, setCategory] = useState(props.meetingItem.category);
    const [prioritized, setPrioritized] = useState(props.meetingItem.prioritized);
    const [status, setStatus] = useState(props.meetingItem.status);
    const [actor, setActor] = useState(props.meetingItem.actor);
    const [dueDate, setDueDate] = useState(props.meetingItem.dueDate);
    const [comment, setComment] = useState("");
    const { instance } = useMsal();
    const appContext = useContext<AppContext>(Context);
    const projects = appContext.state.invoker?.projects || [];
    const userFullName = (appContext.state.invoker.lastName && appContext.state.invoker.firstName) ? (appContext.state.invoker?.firstName + ' ' + appContext.state.invoker?.lastName) : instance.getActiveAccount()?.name;

    const isCreator = props.meetingItem.creator === appContext.state.invoker.email;
    // const editTypes = !props.readOnly && props.allowMetadataEditing && isCreator;
    const editCategory = !props.readOnly && props.allowMetadataEditing && isCreator;
    const editActor = !props.readOnly && props.allowMetadataEditing && isCreator;
    const editDueDate = !props.readOnly && (!props.meetingItem.prioritized || props.meetingItem.creator === instance.getActiveAccount()?.username);
    const editStatus = !props.readOnly;

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        props.onClose();
    }

    const postComment = () => {
        addCommentToItem(props.meetingItem, userFullName, comment, t);
        setComment('');
        props.onUpdate(props.meetingItem);
    }

    const saveChanges = () => {
        props.meetingItem.content = content;
        props.meetingItem.category = category;
        props.meetingItem.prioritized = prioritized;
        props.meetingItem.status = status;
        props.meetingItem.actor = actor;
        props.meetingItem.dueDate = dueDate;
        if (comment.trim()) {
            addCommentToItem(props.meetingItem, userFullName, comment, t);
            setComment('');
        }
        props.onUpdate(props.meetingItem);
        props.onClose();
    }

    console.log('prioritized', prioritized);
    return (
        <Dialog
            fullWidth={true}
            maxWidth="lg"
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}
            PaperProps={{
                style: {
                    backgroundColor: 'white'
                }
            }}
        >
            <DialogTitle sx={{ m: 0, p: 2, fontWeight: 400 }} id="customized-dialog-title">
                {t('item.editPanel')}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={props.onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Box sx={{ flex: '1 1 0' }}>
                        {/* item panel */}

                        <Box>
                            <Typography variant="button" fontWeight={600}>{t('item.details')}</Typography>
                            <Divider sx={{ mb: 2 }} />
                        </Box>
               
                        <Box sx={{
                            display: 'inline-grid',
                            gridTemplateColumns: 'auto 1fr',
                            columnGap: 2,
                            rowGap: 1,
                            alignItems: 'center'
                        }}>
                            {/* meeting------------------------------------------ */}
                            <Typography variant="button" sx={{ color: (theme) => theme.palette.secondary.main }}>
                                {t('item.meetingName')}:
                            </Typography>
                            <Typography variant="body2">
                                {props.meetingItem.meetingName || props.meeting?.name}
                            </Typography>


                            {/* what------------------------------------------ */}
                            <Typography variant="button" sx={{ color: (theme) => theme.palette.secondary.main }}>
                                {t('item.content')}:
                            </Typography>
                            {
                                (props.readOnly || !props.allowMetadataEditing) ?
                                    <Typography variant="body2">
                                        {content}
                                    </Typography>
                                    :
                                    <TextField
                                        sx={{
                                            flexGrow: 1,
                                            mb: 2
                                        }}
                                        id="itemDescription"
                                        fullWidth={true}
                                        autoFocus={true}
                                        required={true}
                                        multiline
                                        rows={3}
                                        value={content}
                                        onChange={(e) => setContent(e.target.value)}
                                    />
                            }


                            {/* types------------------------------------------ */}
                            <Typography variant="button" sx={{ color: (theme) => theme.palette.secondary.main }}>
                                {t('item.types')}:
                            </Typography>
                            <Box sx={{ display: 'flex', alignSelf: 'center' }}>
                                {props.meetingItem.task &&
                                    <Box>
                                        <Tooltip title={t('item.taskType')}>
                                            <TaskIcon color="primary" />
                                        </Tooltip>
                                    </Box>
                                }
                                {props.meetingItem.decision &&
                                    <Box>
                                        <Tooltip title={t('item.decisionType')}>
                                            <SignpostIcon color="primary" />
                                        </Tooltip>
                                    </Box>
                                }
                                {props.meetingItem.info &&
                                    <Box>
                                        <Tooltip title={t('item.informationType')}>
                                            <InfoIcon color="primary" />
                                        </Tooltip>
                                    </Box>
                                }
                            </Box>

                            {/* prioritized------------------------------------------ */}
                            <Typography variant="button" sx={{ color: (theme) => theme.palette.secondary.main }}>
                                {t('item.prioritized')}:
                            </Typography>
                            <Box>
                                <Tooltip title={t('item.prioritized')}>
                                    <Checkbox
                                        icon={<ClearIcon />}
                                        checkedIcon={<TooltipIcon titleKey={'item.prioritized'} icon={<CheckIcon />} />}
                                        sx={{ color: 'secondary.light' }}
                                        checked={prioritized}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPrioritized(event.target.checked)}
                                    />
                                </Tooltip>

                            </Box>

                            {/* category--------------------------------------- */}
                            <Typography variant="button" sx={{ color: (theme) => theme.palette.secondary.main }}>
                                {t('item.category')}:
                            </Typography>
                            {
                                editCategory ?
                                    <TextField
                                        id="category"
                                        value={category}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCategory(event.target.value)}
                                    />
                                    :
                                    <Typography variant="body2">
                                        {props.meetingItem.category}
                                    </Typography>
                            }

                            {/* actor------------------------------------------ */}
                            <Typography variant="button" sx={{ color: (theme) => theme.palette.secondary.main }}>
                                {t('item.actor')}:
                            </Typography>
                            {props.meetingItem.actor ?
                                editActor ?
                                    <Box sx={{
                                        border: '1px solid',
                                        borderColor: 'grey.400',
                                        borderRadius: 1,
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: 300,
                                        pl: '14px',
                                        "&:hover": { borderColor: 'black' }
                                    }}>
                                        <FreeSingleSelect
                                            onValueSelected={(value) => {
                                                setActor(value)
                                            }}
                                            options={[...(appContext.state.selectedList?.participants || []), ...(appContext.state.invoker?.invitedUserEmails || [])]}
                                            value={actor}
                                            disabled={!props.meetingItem.task}
                                        />
                                    </Box>
                                    :
                                    <Typography variant="body2">
                                        {props.meetingItem.actor}
                                    </Typography>
                                :
                                <Typography variant="body2">
                                    {t('item.notAvailable')}
                                </Typography>
                            }

                            {/* duedate--------------------------- */}
                            <Typography variant="button" sx={{ color: (theme) => theme.palette.secondary.main }}>
                                {t('item.dueDate')}:
                            </Typography>

                            {props.meetingItem.dueDate ?
                                editDueDate ?
                                    <DatePicker sx={{ width: 300 }} value={dayjs(dueDate)} onChange={(newDate) => setDueDate(newDate.toDate())} />
                                    :
                                    <Typography variant="body2">
                                        {dateFormatter.format(new Date(props.meetingItem.dueDate))}
                                    </Typography>
                                :
                                <Typography variant="body2">
                                    {t('item.notAvailable')}
                                </Typography>
                            }

                            {/* status---------------------------- */}
                            <Typography variant="button" sx={{ color: (theme) => theme.palette.secondary.main }}>
                                {t('item.status')}:
                            </Typography>
                            {
                                props.meetingItem.task ?
                                    editStatus ?
                                        <Box sx={{ fontSize: '0.875rem' }}>
                                            <FormControl sx={{ width: 300 }}>
                                                <Select
                                                    value={status}
                                                    onChange={(event: { target: { value: string } }) => {
                                                        {
                                                            setStatus(enumFromStringValue(MeetingItemStatus, event.target.value));
                                                        }
                                                    }}
                                                >
                                                    <MenuItem value={MeetingItemStatus.NotStarted}>{t(`item_status.${MeetingItemStatus.NotStarted}`)}</MenuItem>
                                                    <MenuItem value={MeetingItemStatus.InProgress}>{t(`item_status.${MeetingItemStatus.InProgress}`)}</MenuItem>
                                                    <MenuItem value={MeetingItemStatus.Completed}>{t(`item_status.${MeetingItemStatus.Completed}`)}</MenuItem>
                                                    <MenuItem value={MeetingItemStatus.Cancelled}>{t(`item_status.${MeetingItemStatus.Cancelled}`)}</MenuItem>
                                                    <MenuItem value={MeetingItemStatus.OnHold}>{t(`item_status.${MeetingItemStatus.OnHold}`)}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        :
                                        <Box sx={{ width: '150px' }}>
                                            <ItemStatus status={props.meetingItem.status} lastModifiedBy={props.meetingItem.actor} />
                                        </Box>
                                    :
                                    <Box>{t('item.notAvailable')}</Box>}


                            {/* project-------------------------- */}
                            <Typography variant="button" sx={{ color: (theme) => theme.palette.secondary.main }}>
                                {t('meeting.project')}:
                            </Typography>
                            <Typography variant="body2">
                                {
                                    projects.find(
                                        p => props.meetingItem.project === p.id
                                    )?.name
                                }
                            </Typography>

                            {/* tags----------------------------- */}
                            <Typography variant="button" sx={{ color: (theme) => theme.palette.secondary.main }}>
                                {t('meeting.tags')}:
                            </Typography>
                            <Typography variant="body2">
                                {
                                    projects.find(p => p.id === props.meetingItem.project)?.tags.filter(
                                        t => props.meetingItem.tags.includes(t.id)
                                    ).map(t => t.name).join(', ')
                                }
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ flex: '1 1 0' }}>
                        <Box>
                            <Typography variant="button" fontWeight={600}>{t('item.comments')}</Typography>
                            <Divider sx={{ mb: 2 }} />
                        </Box>
                        
                        <TextField
                            sx={{
                                "& .MuiOutlinedInput-root.Mui-disabled": {
                                    backgroundColor: (theme) => theme.palette.background.paper
                                },
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: (theme) => theme.palette.primary.main
                                }
                            }}
                            variant="outlined"
                            id="itemComments"
                            // label={t('item.comments').toUpperCase()}
                            multiline
                            fullWidth={true}
                            disabled={true}
                            rows={11}
                            value={props.meetingItem.comments}
                        />
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mt: 2,
                            gap: 1
                        }}>
                            <TextField
                                sx={{
                                    flexGrow: 1,
                                }}
                                id="addNewItemComment"
                                label={t('item.addComment')}
                                multiline
                                fullWidth={true}
                                rows={2}
                                value={comment}
                                disabled={props.readOnly}
                                onChange={(e) => setComment(e.target.value)}
                            />
                            <IconButton
                                aria-label="post"
                                onClick={postComment}
                                disabled={props.readOnly || !comment.trim()}
                                sx={{
                                    color: (theme) => theme.palette.primary.main,
                                }}
                            >
                                <SendIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ mt: 3 }}>
                    <Typography variant="button" fontWeight={600}>{t('attachment_pane.title')}</Typography>
                    <Divider sx={{ mb: 2 }} />
                </Box>
                
                <Box sx={{ mt:2 }}>
                    <AttachmentUpload
                        allowModifications={props.allowAttachmentsEditing}
                        height='auto'
                        uploadPrefix={`items/meetings/${props.meetingItem.meetingId}/items/${props.meetingItem.id}/`}
                        meetingId={props.meetingItem.meetingId}
                        itemId={props.meetingItem.id}
                        onAttachmentsCountChanged={(count: number, meetingId?: string, itemId?: string) => {
                            props.meetingItem.attachments = count;
                            props.onAttachmentsCountChanged(count, meetingId, itemId)
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" autoFocus disabled={props.readOnly || !content.trim()} onClick={saveChanges}>
                    {t('item.saveChanges')}
                </Button>
            </DialogActions>
        </Dialog >
    );
}