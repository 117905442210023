import { IPublicClientApplication } from '@azure/msal-browser';
import { ApiUser } from '../models';

let msalInstance: IPublicClientApplication | null = null;

export const initializeMsalInstance = (msal: IPublicClientApplication) => {
    msalInstance = msal;
};

export const getMsalInstance = () => {
    if (!msalInstance) {
        throw new Error('MSAL instance not initialized');
    }
    return msalInstance;
};
