import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
// const getBackgroundColor = (color: string, mode: string) =>
//     mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

// const getHoverBackgroundColor = (color: string, mode: string) =>
//     mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

// const getSelectedBackgroundColor = (color: string, mode: string) =>
//     mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

// const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
//     mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '.MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold !important',
        overflow: 'visible !important'
    },
    '& .top-row-theme': {
        '& .MuiDataGrid-cell': {
            boxShadow: 'none',
            backgroundColor: theme.palette.background.default,
        },
    },
    '& .grid-theme': {
        backgroundColor: theme.palette.background.default,
        '&:hover': {
            backgroundColor: theme.palette.action.hover
        },
        '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected
            ,
            '&:hover': {
                backgroundColor: theme.palette.action.focus
            },
        },
        '& .MuiDataGrid-cell': {
            '&.MuiDataGrid-cell--editing': {
                backgroundColor: theme.palette.background.default
            }
        },
        //  '& .MuiDataGrid-toolbarContainer': {
        //     backgroundColor: theme.palette.action.hover
        // },

    },
}));