import { Reducer } from "react";
import { ActionTypes, Actions } from "../actions/common";
import { ApiUser } from "../models";

export const invokerReducer: Reducer<ApiUser, Actions> = (state: ApiUser, action: Actions): ApiUser => {
    switch (action.type) {
        case ActionTypes.SET_INVOKER_USER:
            state = action.payload ? { ...action.payload } : undefined;
            break;
        case ActionTypes.CLEAR_INVOKER_USER:
            state = undefined;
            break;
    }
    return state;
}