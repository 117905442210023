import { MeetingItemStatus } from '../models';
import dateFormatter from '../util/dateFormatter';

const calculateDayDifference = (dueDate: string): number => {
    const time_difference = new Date(dueDate).getTime() - new Date().getTime();
    // console.log("Time difference for date: " + dateFormatter.format(new Date(dueDate)) + " is: " + Math.ceil(time_difference / (1000 * 60 * 60 * 24)));
    return Math.ceil(time_difference / (1000 * 60 * 60 * 24));
}

const calculateProjection = (dayDifference: number, interval: number): number => {
    return (interval + dayDifference) * 100 / (interval * 2);
}

const calculateDueDatesProjections = (dueDates: string[], interval: number): Map<Date, number> => {
    const dueDateProjections = new Map();
    //calculate time difference  
    for (const dueDate of dueDates) {
        const days_difference = calculateDayDifference(dueDate);
        // if (Math.abs(days_difference) <= progressHalfInterval) {
        dueDateProjections.set(dueDate, calculateProjection(days_difference, interval));
        // }
    }
    return dueDateProjections;
};

const TaskTimeline = ({ status, dueDate, previousDueDates }) => {
    const timelineInterval = localStorage.getItem("interval") ? parseInt(localStorage.getItem("interval")) : 30;
    // console.log(timelineInterval);
    //calculate position for dueDates
    const showProgress = () => {
        const progressDots = [];
        let index = 0;
        //show previous dueDates
        const previousDueDatesProjection = calculateDueDatesProjections(previousDueDates, timelineInterval);
        previousDueDatesProjection.forEach((value, key) => {
            if (Math.abs(value) <= 100) {
                const prevDueDateFormatted = dateFormatter.format(new Date(key));
                // console.log("map entries: " + value + " " + key);
                const color = "bg-gray-300";
                progressDots.push(
                    <div key={index++} className="absolute group" style={{ left: `${value}%`, top: '50%', transform: 'translate(-50%, -50%)' }}>
                        <div className={`w-2 h-2 ${color} rounded-full group-hover:cursor-pointer`}>
                            <div className="hidden group-hover:flex fixed left-1/2 transform -translate-x-1/2 top-[-20px]">
                                {`(${index}) ${prevDueDateFormatted}`}
                            </div>
                        </div>
                    </div>)
            } else {
                //this prev due date is not displayed because it's not in the range interval, but we keep up with the ordinal 
                index++;
            }
        });
        
        if (dueDate !== undefined) {
            //show due date
            const dueDateFormatted = dateFormatter.format(new Date(dueDate));
            const dueDateDifference = calculateDayDifference(dueDate);
            const dueDateProjection = calculateProjection(dueDateDifference, timelineInterval);
            if (dueDateProjection >= 0 && dueDateProjection <= 100) {
                //only display due dates in range
                let color = "bg-blue-500";
                if (status === MeetingItemStatus.NotStarted) {
                    color = "bg-slate-700";
                }
                if (status === MeetingItemStatus.Completed) {
                    color = "bg-green-500";
                }
                if (status === MeetingItemStatus.Cancelled
                    || status === MeetingItemStatus.OnHold) {
                    color = "bg-stone-700";
                }
                //if we are on the negative x axis, show uncompleted/notstarted/inprogress dots in red
                if (dueDateProjection < 50 && (status === MeetingItemStatus.NotStarted || status === MeetingItemStatus.InProgress)) {
                    color = "bg-red-500";
                }
                progressDots.push(
                    <div key={index++} className="absolute group" style={{ left: `${dueDateProjection}%`, top: '50%', transform: 'translate(-50%, -50%)' }}>
                        <div className={`w-2 h-2 ${color} rounded-full group-hover:cursor-pointer`}>
                            <div className="hidden group-hover:flex fixed left-1/2 transform -translate-x-1/2 top-[-20px]">
                                {`(${index}) ${dueDateFormatted}`}
                            </div>
                        </div>
                    </div>)
            }
        }
        return progressDots;
    }

    return (
        <div className="flex items-center justify-center min-h-24 bg-white-100 relative">
            <div className="relative">
                {/* Horizontal line */}
                <div className="w-[350px] h-px bg-gray-300 mx-auto relative border-none">
                    {/* Vertical line at the middle - represents TODAY */}
                    <div className="absolute top-[-24px] bottom-0 left-1/2 h-12 w-0.5 bg-red-300 mx-auto border-none"></div>
                </div>
                {showProgress()}
            </div>
        </div>
    );
};

export default TaskTimeline;