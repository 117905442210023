import { Dialog, DialogTitle, IconButton, DialogContent, DialogActions } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';

const ConfirmationPanel = ({ showModal, hideModal, confirmModal, entry, type, action }) => {
    const { t } = useTranslation();

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            hideModal();
    }

    const getTitleForAction = (): string => {
        if (action === "delete") {
            return t('text.delete_confirmation');
        }
        if (action === "archive") {
            return t('text.archive_confirmation');
        }
    }

    const getContentForAction = (): string => {
        if (action === "delete") {
            return t('text.delete_message', { type: type, identifier: identifier() });
        }
        if (action === "archive") {
            return t('text.archive_message', { type: type, identifier: identifier() });
        }
    }

    const getConfirmationButtonForAction = (): string => {
        if (action === "delete") {
            return t('button.delete');
        }
        if (action === "archive") {
            return t('button.archive');
        }
    }

    const identifier = () => {
        if (type === "meeting") {
            return entry?.name;
        }
        if (type === "item") {
            return entry?.identifier || entry?.index;
        }
        if (type === "user") {
            return entry?.email;
        }
        if (type === "project") {
            return entry?.name;
        }
        if (type === "attachment") {
            return entry?.displayName || entry?.name || entry?.url;
        }
    }
    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            onClose={handleClose}
            aria-labelledby="confirmation-dialog"
            open={showModal}
            PaperProps={{
                style: {
                    backgroundColor: 'white'
                }
            }}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="confirmation-dialog-title">
                {getTitleForAction()}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={hideModal}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Typography variant="body2">
                    {getContentForAction()}
                </Typography>
            </DialogContent>
            <DialogActions>
                {/* <Button variant="outlined" onClick={hideModal}>
                    <Typography variant="button" display="block">
                        {t('button.archive')}
                    </Typography>
                </Button> */}
                <Button variant="contained" onClick={confirmModal}>
                    {getConfirmationButtonForAction()}
                </Button>
            </DialogActions>
        </Dialog >
    )
}

export default ConfirmationPanel;

