
import { Dispatch, FC, ReactElement, SetStateAction, useState } from "react"
import { Box, Button, IconButton, Snackbar, Tab, Tabs, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ReactComponent as PublishedIconActive } from "../icons/mark_email_read_fill.svg";
import { ReactComponent as PublishedIcon } from "../icons/mark_email_read.svg";
import { ReactComponent as CurrentVersionActive } from "../icons/mark_email_stack_fill.svg";
import { ReactComponent as CurrentVersion } from "../icons/mark_email_stack.svg";
import { Meeting, MeetingStatus } from "../models/meeting";
import dateFormatter, { dateTimeFormatter } from "../util/dateFormatter";
import { useTranslation } from "react-i18next";
import { meetingStatusLabels } from "../models/meetingRepresentation";
import ShareIcon from '@mui/icons-material/Share';
import { TooltipIcon } from "./tooltipIcon";

interface MeetingHeaderWithTabsProps {
    meeting?: Meeting
    initialValue?: number
    setValue: Dispatch<SetStateAction<number>>
    tabs: { label: string; icon: string, message: string }[]
    secondaryBtnText?: string
    terciaryBtnText?: string
    onSecondaryBtnClick?: () => void
    backRouteParent?: string
}

export const MeetingHeaderWithTabs: FC<MeetingHeaderWithTabsProps> = (props: MeetingHeaderWithTabsProps): ReactElement => {
    const [value, setValue] = useState(props.initialValue || 0);
    const [message, setMessage] = useState(props.tabs[props.initialValue || 0].message);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
        navigator.clipboard.writeText(window.location.toString());
    };

    const iconMappingsFirstTabActive = {
        "archiveIcon": <PublishedIconActive />,
        "dynamicFeedIcon": <CurrentVersion />
    }
    const iconMappingsSecondTabActive = {
        "archiveIcon": <PublishedIcon />,
        "dynamicFeedIcon": <CurrentVersionActive />
    }

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setMessage(props.tabs[newValue].message);
        props.setValue(newValue);
    };


    const publishedDate = props.meeting?.status === MeetingStatus.Published ? (props.meeting?.publishedOn || props.meeting?.updatedDate) : undefined;
    const createdDate = props.meeting?.inReviewOn || props.meeting?.createdDate;
    return (
        <Box>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', marginTop: 1, marginBottom: 1 }}>

                <IconButton onClick={() => (props.backRouteParent && location.state?.blockPrevious) ? navigate(props.backRouteParent) : navigate(-1)}>
                    <ChevronLeftIcon />
                </IconButton>

                <Tabs value={value} onChange={handleChange} variant="fullWidth" sx={{ flex: 2 }}>
                    {props.tabs.map(tab => <Tab label={tab.label}
                        icon={value === 0 ? iconMappingsFirstTabActive[tab.icon] : iconMappingsSecondTabActive[tab.icon]}
                        iconPosition="start" />)}
                </Tabs>

                <Typography variant="body1" sx={{ marginLeft: 1, fontStyle: "italic", flex: 2 }}>
                    {message}
                </Typography>

                <Box sx={{ flexGrow: 1 }} />
                {props.terciaryBtnText &&
                    <Button variant="outlined" onClick={handleClick} className="share-button">
                        <TooltipIcon titleKey={'general.copy_to_clipboard'} icon={<ShareIcon />} />
                        <Typography variant="button" display="block">
                            {t(`button.share`)}
                        </Typography>
                    </Button>
                }
                {props.secondaryBtnText &&
                    <Button variant="outlined" onClick={props.onSecondaryBtnClick}>
                        <Typography variant="button" display="block">
                            {props.secondaryBtnText}
                        </Typography>
                    </Button>
                }
            </Box>
            <Box sx={{
                border: '1px solid',
                borderColor: (theme) => theme.palette.grey[300],
                borderRadius: 1,
                backgroundColor: (theme) => theme.palette.background.default,
                marginTop: 1,
                marginBottom: 1,
                padding: 2
            }}>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', marginTop: 1, marginBottom: 1 }}>
                    <Snackbar
                        message={t('general.copied_to_clipboard')}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        autoHideDuration={1500}
                        onClose={() => setOpen(false)}
                        open={open}
                    />
                    <Box sx={{ flex: '1 1 0' }}>
                        <Typography variant="h5" sx={{ color: (theme) => theme.palette.secondary.main }}>
                            {props.meeting?.name}
                        </Typography>
                        <Box>
                            <Typography variant="overline" sx={{ color: (theme) => theme.palette.secondary.main }}>
                                {t('meeting.date')}:
                            </Typography>
                            <Typography variant="body2" sx={{ display: 'inline', marginLeft: 1, marginRight: 1 }}>
                                {props.meeting?.date ? dateFormatter.format(new Date(props.meeting.date)) : t('item.notAvailable')}
                            </Typography>
                        </Box>

                    </Box>
                    {/* status and creator */}
                    <Box sx={{
                        flex: '1 1 0',
                        width: 0,
                        marginLeft: 2,
                        marginRight: 2,
                        paddingLeft: 2,
                        borderLeft: '1px solid',
                        borderLeftColor: (theme) => theme.palette.grey[300],

                    }}>
                        <Box sx={{
                            display: 'inline-grid',
                            gridTemplateColumns: 'auto 1fr',
                            columnGap: 1,
                            alignItems: 'baseline'
                        }}>
                            <Typography variant="overline" sx={{ color: (theme) => theme.palette.secondary.main }}>
                                {t('details.created_by')}:
                            </Typography>
                            <Typography variant="body2">
                                {props.meeting?.creator}
                            </Typography>
                            <Typography variant="overline" sx={{ color: (theme) => theme.palette.secondary.main }}>
                                {t('details.status')}:
                            </Typography>
                            <Typography variant="body2">
                                {t(meetingStatusLabels[props.meeting?.status])}
                            </Typography>
                        </Box>
                    </Box>
                    {/* created and updated */}
                    <Box sx={{
                        flex: '1 1 0',
                        width: 0,
                        marginLeft: 2,
                        paddingLeft: 2,
                        borderLeft: '1px solid',
                        borderLeftColor: (theme) => theme.palette.grey[300]
                    }}>
                        <Box sx={{
                            display: 'inline-grid',
                            gridTemplateColumns: 'auto 1fr',
                            columnGap: 1,
                            alignItems: 'baseline'
                        }}>
                            <Typography variant="overline" sx={{ color: (theme) => theme.palette.secondary.main }}>
                                {t('details.created_on')}:
                            </Typography>
                            <Typography variant="body2">
                                {createdDate ? dateTimeFormatter.format(new Date(createdDate)) : t('item.notAvailable')}
                            </Typography>
                            <Typography variant="overline" sx={{ color: (theme) => theme.palette.secondary.main }}>
                                {t('details.published_on')}:
                            </Typography>
                            <Typography variant="body2">
                                {publishedDate ? dateTimeFormatter.format(new Date(publishedDate)) : t('details.not_yet_published')}
                            </Typography>
                        </Box>
                    </Box>
                </Box>



                <Box sx={{
                    display: 'inline-grid',
                    gridTemplateColumns: 'auto 1fr',
                    columnGap: 1,
                    alignItems: 'baseline'
                }}>
                    {/* <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', marginTop: 1 }}> */}
                    <Typography variant="overline" sx={{ color: (theme) => theme.palette.secondary.main, marginTop: 1 }}>
                        {t('details.invited')}
                    </Typography>
                    <Box sx={{
                        display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center', marginTop: 1,
                        marginLeft: 2, paddingLeft: 2, borderLeft: '1px solid', borderLeftColor: (theme) => theme.palette.grey[300]
                    }}>
                        {props.meeting?.participants.map((participant) => {
                            return <Typography variant="body2"
                                sx={{
                                    border: '1px solid',
                                    borderColor: (theme) => theme.palette.grey[300],
                                    borderRadius: 5,
                                    paddingRight: 1,
                                    paddingLeft: 1,
                                    width: 'fit-content'
                                }}>
                                {participant}
                            </Typography>
                        })}
                    </Box>

                    {/* <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', marginTop: 1 }}> */}
                    <Typography variant="overline" sx={{ color: (theme) => theme.palette.secondary.main, marginTop: 1 }}>
                        {t('details.viewers')}
                    </Typography>
                    <Box sx={{
                        display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center', marginTop: 1,
                        marginLeft: 2, paddingLeft: 2, borderLeft: '1px solid', borderLeftColor: (theme) => theme.palette.grey[300]
                    }}>
                        {props.meeting?.viewers.map((viewer) => {
                            return <Typography variant="body2"
                                sx={{
                                    border: '1px solid',
                                    borderColor: (theme) => theme.palette.grey[300],
                                    borderRadius: 5,
                                    paddingRight: 1,
                                    paddingLeft: 1,
                                    width: 'fit-content'
                                }}>
                                {viewer}
                            </Typography>
                        })}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

