import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Avatar, Checkbox, Chip } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { GridValidRowModel } from '@mui/x-data-grid';

interface FreeCheckboxMultipleSelectProps<T extends GridValidRowModel = any> {
    onValueSelected: (value: T) => void
    formatValues: (values: T[]) => string[]
    formatInputs?: (values: (object | string)[]) => T[]
    isOptionEqualToValue?: (option: T, value: T) => boolean;
    disableChipDeletion?: boolean
    options: T[]
    disableFreeSolo?: boolean
    values?: T[]
    placeholder?: string
}

const finalTheme = createTheme({
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                // Name of the slot
                inputRoot: {
                    // Some CSS for preventing row height errors
                    paddingRight:'0px !important', 
                    // padding: '6px 0px 6px 0px !important',
                    minWidth: 0,
                    maxHeight: '73px !important',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                   
                },
            },
        },
    },
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const FreeCheckboxMultipleSelect: React.FC<FreeCheckboxMultipleSelectProps> = (props: FreeCheckboxMultipleSelectProps): React.ReactElement => {
    return (
        <ThemeProvider theme={finalTheme}>
            <Autocomplete
                // selectOnFocus
                disableClearable
                //remove end adornment
                forcePopupIcon={false}
                ChipProps={
                    props.disableChipDeletion ? {
                        onDelete: (event) => { event.stopPropagation(); },
                        deleteIcon: <div />
                    } : {}
                }
                multiple
                disableCloseOnSelect
                filterSelectedOptions
                fullWidth
                freeSolo={!props.disableFreeSolo}
                options={props.options || []}
                value={props.values || []}
                renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => (
                        <Chip
                            variant="outlined"
                            title={formatOption(option, props)}
                            label={formatOption(option, props)}
                            size='small'
                            {...getTagProps({ index })}
                        />
                    ))
                }
                renderOption={(renderProps, option, { selected }) => {
                    return <li {...renderProps}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {formatOption(option, props)}
                    </li>
                }
                }
                getOptionLabel={(option) => {
                    return formatOption(option, props)
                }}
                renderInput={(inputParams) => {
                    return <TextField
                        {...inputParams}
                        variant="standard"
                        placeholder={props.values?.length ? "" : (props.placeholder || "")}
                        InputProps={{
                            ...inputParams.InputProps,
                            disableUnderline: true
                        }}
                    />
                }}
                onChange=
                {
                    (event, value, _reason) => {
                        event?.stopPropagation();
                        props.onValueSelected(props.formatInputs ? props.formatInputs(value) : value);
                    }
                }
                isOptionEqualToValue={props.isOptionEqualToValue || ((option, value) => option === value)}

            />
        </ThemeProvider>
    );
}

function formatOption(option: any, props: FreeCheckboxMultipleSelectProps<any>): string {
    if (option) {
        const formatted = props.formatValues([option]);
        if (formatted?.length) {
            return formatted[0];
        }
    }
    return '';
}
