import { Divider, Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { FC, ReactElement, useContext, useMemo, useRef, useState } from "react";
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { TreeViewBaseItem, TreeViewItemId } from '@mui/x-tree-view/models';
import { useTranslation } from "react-i18next";
import { useTreeViewApiRef } from "@mui/x-tree-view";
import { Project } from "../models/project";
import React from "react";
import { parseSelectedProjectIds, parseSelectedTagIds, storeSelectedProjectIds, storeSelectedTagIds } from "../util/filterHandler";
import * as projectActions from '../actions/projectActions';
import { ProjectActions } from '../actions/projectActions';
import { bindActionCreators } from "../actions/actionCreators";
import { AppContext } from "../models/applicationState";
import { Context } from "./context";

interface ProjectPanelProps {
    projects: Project[]
    togglePanel: () => void
}

const ProjectPanel: FC<ProjectPanelProps> = (props: ProjectPanelProps): ReactElement => {
    const { t } = useTranslation();
    const getInitialSelectedIds = () => {
        const selectedProjectIds = parseSelectedProjectIds();
        const selectedTagIds = parseSelectedTagIds();
        return selectedProjectIds.concat(selectedTagIds);
    }
    const [selectedItems, setSelectedItems] = useState<string[]>(getInitialSelectedIds());
    const [selectedProjectIds, setSelectedProjectIds] = useState<string[]>([]);
    const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);
    const [treeModel, setTreeModel] = useState<TreeViewBaseItem[]>([]);
    const toggledItemRef = useRef<{ [itemId: string]: boolean }>({});
    const apiRef = useTreeViewApiRef();

    const appContext = useContext<AppContext>(Context)
    
    const actions = useMemo(() => ({
        projectFilters: bindActionCreators(projectActions, appContext.dispatch) as unknown as ProjectActions,
    }), [appContext.dispatch]);
    
    React.useEffect(() => {
        const projectNodes = [];
        props.projects.forEach((project) => {
            projectNodes.push({
                id: project.id,
                label: project.name,
                children: project.tags?.map((tag) => ({
                    id: tag.id,
                    label: tag.name,
                })),
            });
        });
        setTreeModel(projectNodes);
    }, [props.projects]);

    function getItemDescendantsIds(item: TreeViewBaseItem) {
        const ids: string[] = [];
        item.children?.forEach((child) => {
            ids.push(child.id);
            ids.push(...getItemDescendantsIds(child));
        });

        return ids;
    }

    const getAllItemItemIds = () => {
        const ids: TreeViewItemId[] = [];
        const registerItemId = (item: TreeViewBaseItem) => {
            ids.push(item.id);
            item.children?.forEach(registerItemId);
        };

        treeModel.forEach(registerItemId);

        return ids;
    };

    const handleSelectedItemsChange = (
        event: React.SyntheticEvent,
        newSelectedItems: string[],
    ) => {
        // Select / unselect the children of the toggled item
        const itemsToSelect: string[] = [];
        const itemsToUnSelect: { [itemId: string]: boolean } = {};
        // TODO Projects: Would be nice to mark children as unselectable when parent is selected.
        Object.entries(toggledItemRef.current).forEach(([itemId, isSelected]) => {
            const item = apiRef.current?.getItem(itemId);
            if (isSelected) {
                itemsToSelect.push(...getItemDescendantsIds(item));
            } else {
                getItemDescendantsIds(item).forEach((descendantId) => {
                    itemsToUnSelect[descendantId] = true;
                });
            }
        });

        const newSelectedItemsWithChildren = Array.from(
            new Set(
                [...newSelectedItems, ...itemsToSelect].filter(
                    (itemId) => !itemsToUnSelect[itemId],
                ),
            ),
        );

        setSelectedItems(newSelectedItemsWithChildren);
        const selectedProjectIds = [];
        const selectedTagIds = [];
        for (const itemId of newSelectedItemsWithChildren) {
            const item = apiRef.current?.getItem(itemId);
            if (item?.children) {
                selectedProjectIds.push(itemId);
            } else {
                selectedTagIds.push(itemId);
            }
        }
        setSelectedProjectIds(selectedProjectIds);
        setSelectedTagIds(selectedTagIds);
        toggledItemRef.current = {};
    };

    const handleSelectClick = () => {
        setSelectedItems((oldSelected) =>
            oldSelected.length === 0 ? getAllItemItemIds() : [],
        );
    };
    const handleItemSelectionToggle = (
        event: React.SyntheticEvent,
        itemId: string,
        isSelected: boolean,
    ) => {
        toggledItemRef.current[itemId] = isSelected;
    };

    return (
        <Box
            sx={{ width: 400, marginTop: 10, marginLeft: 1 }}
            role="presentation"
            overflow={'auto'}
        // onClick={props.togglePanel}
        // onKeyDown={props.togglePanel}
        >
            <Button variant="outlined" onClick={handleSelectClick} sx={{ margin: 2 }}>
                <Typography variant="button" display="block">
                    {selectedItems.length === 0 ? t('button.select_all') : t('button.unselect_all')}
                </Typography>
            </Button>
            <Divider />
            <RichTreeView
                multiSelect
                checkboxSelection
                items={treeModel}
                selectedItems={selectedItems}
                onSelectedItemsChange={handleSelectedItemsChange}
                apiRef={apiRef}
                onItemSelectionToggle={handleItemSelectionToggle} />
            <Button variant="contained"
                onClick={() => {
                    storeSelectedProjectIds(selectedProjectIds);
                    storeSelectedTagIds(selectedTagIds);
                    props.togglePanel();
                    actions.projectFilters.setSelectedProjectFilters({ projectIds: selectedProjectIds, tagIds: selectedTagIds});
                    // if (window.location.pathname.includes('dashboard')) {
                    //     window.location.reload();
                    // }
                }}
                sx={{ margin: 2 }}>
                <Typography variant="button" display="block">
                    {t('button.filter')}
                </Typography>
            </Button>
        </Box>
    );
};

export default ProjectPanel;