import config from "../config";
import { CalendarEvent, ApiCalendarEvent } from "../models/calendarEvent";
import { EventService } from "../services/eventService";
import { QueryOptions } from "@testing-library/react";
import { ActionMethod, PayloadAction, createPayloadAction } from "./actionCreators";
import { Dispatch } from "react";
import { ActionTypes } from "./common";

const eventService = new EventService(config.api.baseUrl, '/events');

export interface EventActions {
    select(event: CalendarEvent): Promise<CalendarEvent>
}

export const list = async (options?: QueryOptions): Promise<ApiCalendarEvent[]> => {
    return await eventService.getList(options);
}

export const select = (event: CalendarEvent): ActionMethod<CalendarEvent> => (dispatch: Dispatch<SelectEventAction>) => {
    dispatch(selectEventAction(event));
    return Promise.resolve(event);
}

export interface SelectEventAction extends PayloadAction<string, CalendarEvent | undefined> {
    type: ActionTypes.SELECT_CALENDAR_EVENT
}

const selectEventAction = createPayloadAction<SelectEventAction>(ActionTypes.SELECT_CALENDAR_EVENT);