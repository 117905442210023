import { Reducer } from "react";
import { ActionTypes, Actions } from "../actions/common";
import { MeetingItem } from "../models"

export const selectedItemReducer: Reducer<MeetingItem | undefined, Actions> = (state: MeetingItem | undefined, action: Actions): MeetingItem | undefined => {
    switch (action.type) {
        case ActionTypes.SELECT_ITEM:
        case ActionTypes.LOAD_ITEM:
            state = action.payload ? { ...action.payload } : undefined;
            break;
        case ActionTypes.LOAD_LIST:
            state = undefined;
            break;
        case ActionTypes.DELETE_ITEM:
            if (state && state.id === action.payload) {
                state = undefined;
            }
    }

    return state;
}