import {
    FC,
    ReactElement,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { Context } from "./context";
import { AppContext } from "../models/applicationState";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { GridActionsCellItem, GridCellParams, GridColDef, GridRowId } from "@mui/x-data-grid";
import { MeetingItemRepresentation, categoryColumn, contentColumn, dueDateColumn, getMeetingItemFromRepresentation, indexColumn, isStatusUpdated, statusColumn, statusTranslatedLabelOptions, typesColumn } from "../models/meetingItemRepresentation";
import { Checkbox, Tooltip } from "@mui/material";
import { AttachmentsPane } from "./attachmentsPane";
import InfoIcon from '@mui/icons-material/Info';
import TaskIcon from '@mui/icons-material/Task';
import SignpostIcon from '@mui/icons-material/Signpost';
import { useTranslation } from "react-i18next";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EditableGrid from "./editableGrid";
import ItemStatus from "./itemStatus";
import { TooltipIconWithBadge } from "./tooltipIconWithBadge";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ItemEditingPanel, addStatusUpdateSystemCommentToItem, hasUserComments } from "./itemEditingPanel";
import { bindActionCreators } from "../actions/actionCreators";
import { ItemActions } from "../actions/itemActions";
import * as itemActions from "../actions/itemActions";
import { Meeting, MeetingStatus } from "../models/meeting";
import { useMsal } from "@azure/msal-react";

interface LibraryItemsPageTabProps {
    meeting: Meeting;
    rows: MeetingItemRepresentation[]
}

export const LibraryItemsPageTab: FC<LibraryItemsPageTabProps> = (props: LibraryItemsPageTabProps): ReactElement => {
    const ITEM = "item";
    const ITEM_SORT_FIELD = "item.sortField";
    const ITEM_SORT_DIRECTION = "item.sortDirection";
    const { t } = useTranslation();
    const appContext = useContext<AppContext>(Context);
    const [showAttachmentsPane, setShowAttachmentsPane] = useState(false);
    const [items, setItems] = useState<MeetingItemRepresentation[]>(props.rows);
    const [showAttachmentsForItem, setShowAttachmentsForItem] = useState(undefined);
    const [showEditingPanelForItem, setShowEditingPanelForItem] = useState(undefined);
    const { instance } = useMsal();

    const actions = useMemo(
        () => ({
            items: bindActionCreators(
                itemActions,
                appContext.dispatch
            ) as unknown as ItemActions,
        }),
        [appContext.dispatch]
    );

    const openItemAttachments = (id: GridRowId, meetingId: string) => () => {
        setShowAttachmentsForItem({ id, meetingId });
    };

    useEffect(() => {
        setItems(props.rows);
    }, [props.rows]);

    const openItemEditingPanel = (item: MeetingItemRepresentation) => () => {
        setShowEditingPanelForItem({ item });
    };

    const onItemUpdated = async (itemRepresentation: MeetingItemRepresentation) => {
        const statusUpdated = isStatusUpdated(itemRepresentation);
        if (statusUpdated) {
            const userFullName = (appContext.state.invoker.lastName && appContext.state.invoker.firstName) ? (appContext.state.invoker?.firstName + ' ' + appContext.state.invoker?.lastName) : instance.getActiveAccount()?.name;
            addStatusUpdateSystemCommentToItem(itemRepresentation, userFullName, t)
        }
        const item = getMeetingItemFromRepresentation(itemRepresentation);
        await actions.items.save(itemRepresentation.meetingId, item);
    }

    const itemAttachmentsActions = (id: GridRowId, row: MeetingItemRepresentation) => {
        return ([
            <GridActionsCellItem
                icon={
                    <TooltipIconWithBadge
                        badgeContent={row.attachments}
                        titleKey='dashboard.itemAttachments'
                        icon={<AttachFileIcon />}
                    />
                }
                label={t('dashboard.itemAttachments_label')}
                onClick={openItemAttachments(id, row.meetingId)}
                sx={{
                    color: 'primary.main',
                }}
            />,
            <GridActionsCellItem
                icon={<TooltipIconWithBadge titleKey={'general.open'} icon={<ChevronRightIcon />} badgeContent={hasUserComments(row) ? "i" : undefined} />}
                label="Edit"
                onClick={openItemEditingPanel(row)}
                sx={{
                    color: 'primary.main',
                }}
            />]
        );
    }

    const columns: GridColDef[] = [
        {
            ...indexColumn,
            valueFormatter: (_value, row) => row.index?.toString().padStart(2, '0')
        },
        { ...categoryColumn, editable: false },
        { ...contentColumn, editable: false, flex: 0.6 },
        {
            ...typesColumn as GridColDef,
            renderCell: (params) => (
                <Box>
                    {params.row.task
                        && (
                            <Tooltip title={t('item.taskType')}>
                                <span>
                                    <Checkbox
                                        disabled
                                        checkedIcon={<TaskIcon />}
                                        checked={true} />
                                </span>
                            </Tooltip>
                        )}
                    {params.row.decision && (
                        <Tooltip title={t('item.decisionType')}>
                            <span>
                                <Checkbox
                                    disabled
                                    checkedIcon={<SignpostIcon />}
                                    checked={true} />
                            </span>
                        </Tooltip>
                    )}
                    {params.row.info
                        && (
                            <Tooltip title={t('item.informationType')}>
                                <span>
                                    <Checkbox
                                        disabled
                                        // icon={<InfoOutlinedIcon />}
                                        checkedIcon={<InfoIcon />}
                                        checked={true} />
                                </span>
                            </Tooltip>
                        )}
                </Box>
            ),
        },
        { field: 'actor', headerName: 'item.actor', flex: 0.3, editable: false },
        { ...dueDateColumn, editable: false },
        {
            ...statusColumn, editable: false,
            type: 'singleSelect',
            valueFormatter: (_value, row) => t(`item_status.${row?.status?.value}`),
            valueOptions: statusTranslatedLabelOptions(t),
            renderCell: (params: GridCellParams) => (
                params.row.task ? <ItemStatus status={params.row.status} lastModifiedBy={params.row.statusLastUpdatedBy}></ItemStatus>
                    : <Box>{t('item.notAvailable')}</Box>
            ),
        },
    ];

    const bottomColumns: GridColDef[] = columns;

    return (
        <Box>
            <CssBaseline />

            {showAttachmentsPane && (
                <AttachmentsPane
                    allowModifications={false}
                    height='70vh'
                    uploadPrefix={`meetings/${appContext.state.selectedList?.id}/`}
                    meetingId={appContext.state.selectedList?.id}
                    onClose={() => setShowAttachmentsPane(false)}
                    onAttachmentsCountChanged={(count, _meetingId, _itemId) => { appContext.state.selectedList.attachments = count }}
                />
            )}

            {showAttachmentsForItem
                && (
                    <AttachmentsPane
                        allowModifications={false}
                        height='70vh'
                        uploadPrefix={`items/meetings/${showAttachmentsForItem.meetingId}/items/${showAttachmentsForItem.id}/`}
                        meetingId={showAttachmentsForItem.meetingId}
                        itemId={showAttachmentsForItem.id}
                        onClose={() => setShowAttachmentsForItem(undefined)}
                        onAttachmentsCountChanged={(count, _meetingId, itemId) => setItems(items.map(item => item.id === itemId ? { ...item, attachments: count } : item))}
                    />
                )}

            {showEditingPanelForItem
                && (
                    <ItemEditingPanel 
                    allowAttachmentsEditing={false}
                    readOnly={showEditingPanelForItem.item.isSnapshot} 
                    allowMetadataEditing={props.meeting.status !== MeetingStatus.Published} 
                    meetingItem={showEditingPanelForItem.item} open={showEditingPanelForItem} 
                    meeting={props.meeting}
                    onClose={() => setShowEditingPanelForItem(undefined)} 
                    onUpdate={onItemUpdated}
                    onAttachmentsCountChanged={(count, _meetingId, itemId) => setItems(items.map(item => item.id === itemId ? { ...item, attachments: count } : item))}
                    />
                )}

            <Container maxWidth={false} disableGutters>
                <EditableGrid
                    rows={items}
                    columns={bottomColumns}
                    height='80vh'
                    modelType={ITEM}
                    actionsHeaderKey=''
                    extraActions={itemAttachmentsActions}
                />
            </Container>
        </Box>
    );
};
