import { GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid';
import { withApplicationInsights } from "../../components/telemetry";
import { useContext, useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from '../../actions/actionCreators';
import { AppContext } from '../../models/applicationState';
import { Context } from '../../components/context';
import * as eventActions from '../../actions/eventActions';
import CheckIcon from '@mui/icons-material/Check';
import * as listActions from "../../actions/listActions";
import { ListActions } from "../../actions/listActions";
import { EventActions } from '../../actions/eventActions';
import { CalendarEvent } from '../../models/calendarEvent';
import { useNavigate } from 'react-router-dom';
import dateFormatter from '../../util/dateFormatter';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { useTranslation } from 'react-i18next';
import { TooltipIcon } from '../../components/tooltipIcon';
import { StyledDataGrid } from '../../components/styledGrid';
import { CircularProgress } from '@mui/material';
import { Meeting } from '../../models';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const Events = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const appContext = useContext<AppContext>(Context);
    const actions = useMemo(() => ({
        lists: bindActionCreators(
            listActions,
            appContext.dispatch
        ) as unknown as ListActions,
        events: bindActionCreators(eventActions, appContext.dispatch) as unknown as EventActions,
    }), [appContext.dispatch]);
    const [loading, setLoading] = useState(true);

    const [events, setEvents] = useState<CalendarEvent[]>([]);

    const bucketMeetingsByEventId = (meetings: Meeting[]) => {
        return meetings.reduce((acc, meeting) => {
            if (meeting.eventId) {
                acc[meeting.eventId] = meeting;
            }
            return acc;
        }, {} as { [eventId: string]: Meeting });
    }

    const [meetings, setMeetings] = useState(bucketMeetingsByEventId(appContext.state.lists));

    useEffect(() => {
        setMeetings(bucketMeetingsByEventId(appContext.state.lists));
    }, [appContext.state.lists, navigate]);

    const sortEvents = (events: CalendarEvent[]) => {
        return [...(events || [])]?.sort((a, b) => {
            if (a.startDate < b.startDate) return -1;
            if (a.startDate > b.startDate) return 1;
            return 0;
        });
    }

    async function fetchData() {
        // TODO Mircea: handle timezone?
        try {
            const response = await eventActions.list();
            setEvents(sortEvents(response.map(e => ({
                id: e.id,
                uid: e.uid,
                name: e.subject,
                startDate: new Date(e.start.dateTime),
                creator: e.organizer.emailAddress.address,
                invitees: e.attendees.map(a => a.emailAddress.address)
            }))));
            setLoading(false);
        } catch (e) {
            console.info(`Could not retrieve events - ${e}`)
        }
    }

    // Handle items changed
    useEffect(() => {
        fetchData();
    }, [navigate]);

    const handleSelect = (id: GridRowId) => () => {
        onEventSelected(events.find(e => e.id == id.toString()));
    };

    const onMeetingSelected = async (meeting: Meeting) => {
        actions.lists.select(meeting);
    }

    const onNavigate = (meeting: Meeting) => {
        if (meeting) {
            onMeetingSelected(meeting);
            navigate(`/meetings/${meeting.id}`, { state: { blockPrevious: true } });
        }
    };

    const onEventSelected = async (event: CalendarEvent) => {
        const existingMeeting = meetings[event.id];
        if (existingMeeting) {
            onNavigate(existingMeeting);
            return;
        }
        console.info(`Creating meeting for event ${event.id}`);
        const saved = await actions.lists.save({
            name: event.name,
            date: event.startDate,
            participants: event.invitees,
            eventId: event.id
        });
        console.info(`Navigating to meeting ${saved.id}`);
        onNavigate(saved);
    }

    const columns: GridColDef[] = [
        { field: 'name', headerName: t('events.name'), editable: false, width: 300 },
        {
            field: 'startDate',
            headerName: t('events.startDate'),
            editable: false,
            width: 120,
            valueFormatter: value =>
                value ? dateFormatter.format(new Date(value)) : "-"
        },
        { field: 'creator', headerName: t('events.creator'), editable: false, width: 500 },
        { field: 'invitees', headerName: t('events.invitees'), editable: false, width: 500 },
        {
            field: 'actions',
            type: 'actions',
            headerName: t('events.actions'),
            cellClassName: 'actions',
            getActions: ({ id }) => {

                return [
                    <GridActionsCellItem
                        icon={
                            meetings[id.toString()]
                                ?
                                <TooltipIcon titleKey={'general.open'} icon={<ChevronRightIcon />} />
                                :
                                <TooltipIcon titleKey={'events.actions'} icon={<CheckIcon />} />
                        }
                        label="Select"
                        sx={{
                            color: 'primary.main',
                        }}
                        onClick={handleSelect(id)}
                    />,
                ];

            },
        },
    ];

    return (
        <Box>
            {loading &&
                <Box style={{ display: 'inline' }}>
                    <CircularProgress size={30} />
                </Box>}
            {!loading &&
                <Box sx={{ backgroundColor: "background.default" }}>
                    <CssBaseline />
                    <div style={{ height: '90vh', width: '100%' }}>
                        <StyledDataGrid
                            rows={events}
                            columns={columns}
                        />
                    </div>
                </Box>
            }
        </Box>
    );
};

export default withApplicationInsights(Events, "Events");
