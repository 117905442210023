import config from "../config";
import { DashboardService } from "../services/dashboardService";
import { QueryOptions } from "@testing-library/react";
import { DashboardData, MeetingItem } from "../models";
import getAxiosFactory from "../services/customAxios";

const dashboardService = new DashboardService(config.api.baseUrl, '/dashboard/actor');

export const list = async (options?: QueryOptions): Promise<DashboardData[]> => {
    return await dashboardService.getList(options);
}

export const listDiffs = async (options?: QueryOptions): Promise<MeetingItem[]> => {
    const axiosFactory = getAxiosFactory(config.api.baseUrl, '/dashboard/actor/diffs');
    const realClient = await axiosFactory();
    const response = await realClient.request<MeetingItem[]>({
        method: 'GET'
    });
    return response?.data || [];
}
