import { FC, ReactElement } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { AttachmentUpload } from "./attachmentUpload";
import { t } from "i18next";

interface AttachmentsPaneProps {
    height: string
    allowModifications: boolean;
    meetingId?: string;
    itemId?: string;
    onClose?: () => Promise<void> | void;
    onAttachmentsCountChanged?: (count: number, meetingId?: string, itemId?: string) => Promise<void> | void;
    uploadPrefix?: string;
    contextDisplayName?: string;
}

export const AttachmentsPane: FC<AttachmentsPaneProps> = (props: AttachmentsPaneProps): ReactElement => {
    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        props.onClose();
    }

    return (
        <Dialog
            fullWidth={true}   
            maxWidth="md"
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={true}
            PaperProps={{
                style: {
                    backgroundColor: 'white'
                }
            }}
        >
            <DialogTitle sx={{ m: 0, p: 2, fontWeight: 400 }} id="customized-dialog-title">
                {t('attachment_pane.title')}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={props.onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <AttachmentUpload
                    height={props.height}
                    meetingId={props.meetingId}
                    itemId={props.itemId}
                    allowModifications={props.allowModifications}
                    uploadPrefix={props.uploadPrefix}
                    contextDisplayName={props.contextDisplayName}
                    onAttachmentsCountChanged={props.onAttachmentsCountChanged}
                />
            </DialogContent>
        </Dialog >
    );
}