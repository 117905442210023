import { GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport, GridToolbarContainer, GridColDef } from "@mui/x-data-grid";
import { Button, Popover, Paper, Box, Divider, Slider, Typography } from "@mui/material";
import MuiToggleButton from "@mui/material/ToggleButton";
import ClearIcon from '@mui/icons-material/Clear';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import React, { FC, ReactElement, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { parseSelectedProjectIds, parseSelectedTagIds, storeSelectedProjectIds, storeSelectedTagIds } from "../util/filterHandler";
import * as projectActions from '../actions/projectActions';
import { ProjectActions } from '../actions/projectActions';
import { bindActionCreators } from "../actions/actionCreators";
import { AppContext } from "../models/applicationState";
import { Context } from "./context";
import { styled } from "@mui/material/styles";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

interface CustomToolbarProps {
    showSettings: boolean,
    interval?: number;
    updateInterval?: (newInterval: number) => void;
    onArchived?: (archived: boolean) => void;
    onCompleted?: (completed: boolean) => void;
    onInactive?: (inactive: boolean) => void;
    onShowOnlyPrioritized?: (onlyPrioritized: boolean) => void;
    updateColumns?: GridColDef[];
    hideItemStatusToggles?: boolean;
}

export const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
    border: 'none',
    "&.MuiToggleButton-root": {
        color: theme.palette.primary.main
    },
    "&.Mui-selected": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.action.selected,
        "&:hover": {
            backgroundColor: theme.palette.action.focus,
        },
    },
    "&:hover": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.action.hover,
    },
}));

const CustomToolbar: FC<CustomToolbarProps> = (props: CustomToolbarProps): ReactElement => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );
    const [projectFilters, setProjectFilters] = React.useState(parseSelectedProjectIds().length + parseSelectedTagIds().length);
    const [archived, setArchived] = React.useState(false);
    const [showCompleted, setShowCompleted] = React.useState(false);
    const [showInactive, setShowInactive] = React.useState(false);
    const [showOnlyPrioritized, setShowOnlyPrioritized] = React.useState(false);

    const appContext = useContext<AppContext>(Context)

    const actions = useMemo(() => ({
        projectFilters: bindActionCreators(projectActions, appContext.dispatch) as unknown as ProjectActions,
    }), [appContext.dispatch]);

    useEffect(() => {
        setProjectFilters(parseSelectedProjectIds().length + parseSelectedTagIds().length);
    }, [appContext.state.selectedProjectFilters])

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        setAnchorEl(null);
        setOpen(false);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleClearProjectFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
        //clear project filters from local storage
        storeSelectedProjectIds([]);
        storeSelectedTagIds([]);
        setProjectFilters(0);
        actions.projectFilters.setSelectedProjectFilters({ projectIds: [], tagIds: [] });
    };

    const changeProgressInterval = (event: Event, selection: number) => {
        props.updateInterval(selection);
        window.localStorage.setItem("interval", `${selection}`);
    }

    const marks = [
        {
            value: 1,
            label: `1 ${t('settings.day')}`,
        },
        {
            value: 90,
            label: `90 ${t('settings.days')}`
        },
    ];

    const style = {
        width: 500,
        bgcolor: '#fff',
        p: 4,
    };

    const id = open ? "simple-popover" : undefined;
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />

            {props.showSettings &&
                <>
                    <Button
                        aria-describedby={id}
                        startIcon={<SettingsOutlinedIcon />}
                        onClick={handleClick}
                    >
                        {t('toolbar.settings')}
                    </Button>

                    <Popover
                        id={id}
                        open={open}
                        onClose={handleClose}
                        anchorEl={anchorEl}
                        role={undefined}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left"
                        }}
                    >
                        <Paper>
                            <Box sx={style}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    {t('settings.title')}
                                </Typography>
                                <Divider />
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    {t('settings.interval')}
                                </Typography>
                                <Box sx={{ width: 300, display: 'inline' }}>
                                    <Slider
                                        aria-label="Always visible"
                                        value={props.interval}
                                        step={1}
                                        min={1}
                                        max={90}
                                        marks={marks}
                                        valueLabelDisplay="on"
                                        onChange={changeProgressInterval}
                                        color='secondary'
                                        size='small'
                                        sx={{ mt: 4 }}
                                    />
                                </Box>
                            </Box>
                        </Paper>
                    </Popover>
                </>
            }

            <ToggleButton
                value="archived"
                selected={archived}
                onChange={() => {
                    const newArchived = !archived;
                    setArchived(newArchived);
                    props.onArchived && props.onArchived(newArchived);
                }}
                size='small'
                color="primary"
            >
                {archived ? <><CheckBoxIcon fontSize='small' sx={{ mr: 1 }} /> {t('toolbar.showArchived')} </>
                    : <><CheckBoxOutlineBlankIcon fontSize='small' sx={{ mr: 1 }} />{t('toolbar.showArchived')}</>}
            </ToggleButton>

            {!props.hideItemStatusToggles &&
                <ToggleButton
                    value="completed"
                    selected={showCompleted}
                    onChange={() => {
                        const newCompleted = !showCompleted;
                        setShowCompleted(newCompleted);
                        props.onCompleted && props.onCompleted(newCompleted);
                    }}
                    size='small'
                >
                    {!showCompleted ? <><CheckBoxOutlineBlankIcon fontSize='small' sx={{ mr: 1 }} />{t('toolbar.showCompleted')}</>
                        : <><CheckBoxIcon fontSize='small' sx={{ mr: 1 }} /> {t('toolbar.showCompleted')} </>}
                </ToggleButton>
            }

            {!props.hideItemStatusToggles &&
                <ToggleButton
                    value="inactive"
                    selected={showInactive}
                    onChange={() => {
                        const newInactive = !showInactive;
                        setShowInactive(newInactive);
                        props.onInactive && props.onInactive(newInactive);
                    }}
                    size='small'
                >
                    {!showInactive ? <><CheckBoxOutlineBlankIcon fontSize='small' sx={{ mr: 1 }} />{t('toolbar.showInactive')}</>
                        : <><CheckBoxIcon fontSize='small' sx={{ mr: 1 }} /> {t('toolbar.showInactive')} </>}
                </ToggleButton>
            }

            {!props.hideItemStatusToggles &&
                <ToggleButton
                    value="prioritized"
                    selected={showOnlyPrioritized}
                    onChange={() => {
                        const newShowOnlyPrioritized = !showOnlyPrioritized;
                        setShowOnlyPrioritized(newShowOnlyPrioritized);
                        props.onShowOnlyPrioritized && props.onShowOnlyPrioritized(newShowOnlyPrioritized);
                    }}
                    size='small'
                >
                    {!showOnlyPrioritized ? <><CheckBoxOutlineBlankIcon fontSize='small' sx={{ mr: 1 }} />{t('toolbar.showOnlyPrioritized')}</>
                        : <><CheckBoxIcon fontSize='small' sx={{ mr: 1 }} /> {t('toolbar.showOnlyPrioritized')} </>}
                </ToggleButton>
            }

            {projectFilters > 0 &&
                <Button
                    sx={{
                        background: (theme) => theme.palette.action.selected,
                        "&:hover": {
                            backgroundColor: (theme) => theme.palette.action.focus,
                        },
                    }}
                    startIcon={<ClearIcon />}
                    onClick={handleClearProjectFilters}>
                    {t('toolbar.clear_project_filter', { count: projectFilters })}
                </Button>}
        </GridToolbarContainer>
    )
};

export default CustomToolbar;
