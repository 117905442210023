import { Reducer } from "react";
import { ActionTypes, Actions } from "../actions/common";
import { CalendarEvent } from "../models/calendarEvent";

export const selectedEventReducer: Reducer<CalendarEvent, Actions> = (state: CalendarEvent, action: Actions): CalendarEvent => {
    switch (action.type) {
        case ActionTypes.SELECT_CALENDAR_EVENT:
            state = action.payload ? { ...action.payload } : undefined;
            break;
    }

    return state;
}