import { GridCellModes, GridCellModesModel, GridCellParams, GridEventListener, GridRowId, GridRowModes, GridRowModesModel, GridRowParams } from "@mui/x-data-grid";
import React from "react";

export function handleCellModesModelChange(setCellModesModel: React.Dispatch<React.SetStateAction<GridCellModesModel>>) {
    return (newModel: GridCellModesModel) => {
        setCellModesModel(newModel);
    };
}

export function handleCellClickAsEditing(setCellModesModel: React.Dispatch<React.SetStateAction<GridCellModesModel>>, setChangedRow: React.Dispatch<React.SetStateAction<GridRowId>>) {
    return (params: GridCellParams, event: React.MouseEvent) => {
        if (!params.isEditable) {
            return;
        }

        // Ignore portal
        if (
            (event.target as any).nodeType === 1 &&
            !event.currentTarget.contains(event.target as Element)
        ) {
            return;
        }

        setCellModesModel((prevModel) => {
            return {
                // Revert the mode of the other cells from other rows
                ...Object.keys(prevModel).reduce(
                    (acc, id) => ({
                        ...acc,
                        [id]: Object.keys(prevModel[id]).reduce(
                            (acc2, field) => ({
                                ...acc2,
                                [field]: { mode: GridCellModes.View },
                            }),
                            {},
                        ),
                    }),
                    {},
                ),
                [params.id]: {
                    // Revert the mode of other cells in the same row
                    ...Object.keys(prevModel[params.id] || {}).reduce(
                        (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
                        {},
                    ),
                    [params.field]: { mode: GridCellModes.Edit },
                },
            };
        });
        setChangedRow(params.id);
    };
}

export function handleRowClickAsEditing(setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>) {
    return (params: GridRowParams, event: React.MouseEvent) => {

        // Ignore portal
        if (
            (event.target as any).nodeType === 1 &&
            !event.currentTarget.contains(event.target as Element)
        ) {
            return;
        }

        setRowModesModel((_prevModel) => {
            return { [params.row.id]: { mode: GridRowModes.Edit } };
        });
    };
}